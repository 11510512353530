{
  "name": "napa-front",
  "version": "0.3.14",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:local": "ng serve -c local",
    "start:staging": "ng serve -c staging",
    "build": "ng build",
    "build:staging": "ng build --configuration staging",
    "build:development": "ng build --configuration development",
    "watch": "ng build --watch --configuration development",
    "test-headless": "ng test --watch=false --browsers=ChromeHeadless",
    "test": "ng test",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "prepare": "husky"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.0",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.0.0",
    "@angular/compiler": "^17.0.0",
    "@angular/core": "^17.0.0",
    "@angular/forms": "^17.0.0",
    "@angular/material": "^17.3.10",
    "@angular/platform-browser": "^17.0.0",
    "@angular/platform-browser-dynamic": "^17.0.0",
    "@angular/router": "^17.0.0",
    "@ngrx/effects": "^17.2.0",
    "@ngrx/entity": "^17.2.0",
    "@ngrx/store": "^17.2.0",
    "@ngrx/store-devtools": "^17.2.0",
    "@rx-angular/template": "^17.1.0",
    "@types/hammerjs": "^2.0.46",
    "@types/swiper": "^6.0.0",
    "@wlcm/angular": "^17.7.15",
    "angular-imask": "^7.6.1",
    "angular-svg-icon": "^17.0.0",
    "date-fns": "^3.6.0",
    "date-fns-tz": "^3.2.0",
    "grapesjs": "^0.22.4",
    "grapesjs-mjml": "^1.0.6",
    "grapesjs-preset-webpage": "^1.0.3",
    "hammerjs": "^2.0.8",
    "imask": "^7.6.1",
    "libphonenumber-js": "^1.11.7",
    "lodash": "^4.17.21",
    "lodash-es": "^4.17.21",
    "ng-inline-svg-2": "^15.0.1",
    "ng2-pdf-viewer": "^10.2.2",
    "ngrx-store-localstorage": "^17.0.0",
    "ngx-quill": "^25.3.2",
    "quill": "^2.0.3",
    "rxjs": "~7.8.0",
    "socket.io-client": "^4.8.1",
    "swiper": "^11.1.14",
    "tslib": "^2.3.0",
    "uuid": "^10.0.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.0",
    "@angular-eslint/builder": "17.5.1",
    "@angular-eslint/eslint-plugin": "17.5.1",
    "@angular-eslint/eslint-plugin-template": "17.5.1",
    "@angular-eslint/schematics": "17.5.1",
    "@angular-eslint/template-parser": "17.5.1",
    "@angular/cli": "^17.0.0",
    "@angular/compiler-cli": "^17.0.0",
    "@types/google.maps": "^3.55.11",
    "@types/jasmine": "~5.1.0",
    "@types/lodash": "^4.17.5",
    "@types/node": "^20.14.2",
    "@types/uuid": "^10.0.0",
    "@typescript-eslint/eslint-plugin": "7.10.0",
    "@typescript-eslint/parser": "7.10.0",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.1.3",
    "husky": "^9.0.11",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lint-staged": "^15.2.4",
    "prettier": "^3.2.5",
    "prettier-eslint": "^16.3.0",
    "typescript": "~5.2.2"
  }
}
